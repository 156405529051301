import * as React from "react";
import "./Map.css";
import Map_marker from "../Map-marker/Map_marker";

class Map extends React.Component {
  public render() {
    return (
      <div>
        <div className="map">
          <Map_marker />
        </div>
        <div className="mapmarker mobile">
          <Map_marker />
        </div>
      </div>
    );
  }
}
export default Map;
