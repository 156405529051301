import * as React from "react";
import "./Introduction.css";

class Introduction extends React.Component {
  public render() {
    return (
      <div className="intro">
        <div className="edfWrapper">
          <h1>
            EDF WORLD.
            <div className="line" />
          </h1>
          <p>Get Everyone Shopping</p>
        </div>
      </div>
    );
  }
}
export default Introduction;
