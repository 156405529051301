import * as React from "react";
import "./About.css";

class About extends React.Component {
  public render() {
    return (
      <div className="aboutbox">
        {/* <div className="edfWrapper1"> */}
        <h2>ABOUT EDF WORLD</h2>
        <hr className="hrAbout" />
        <br />
        <p>
          EDF World is a travel retailer which had swiftly established its
          international presence with its duty free outlets.
          Dynamic and decisive, EDF World is driven to take travel retail
          experience to a whole different level unlike any before, for both the
          young adventurous travellers and the seasoned travellers.
        </p>
        <p>
          EDF World deals with exclusive and established travel products around
          the world and holds brands in beer, spirits and wines. EDF World also
          carry a wide range of new and innovative beauty and health products
          specifically sourced from Japan and Korea.
        </p>
        <p>
          EDF World’s stocks are always ready to meet the needs and demands of
          our clients.
        </p>
        <p>
          In Indonesia, EDF World has secured the exclusive rights to operate
          its impressive flagship outlet at Bandar Bentan Telani Ferry Terminal.
          It is the largest and only concept travel retail outlet in Bintan
          Resort. EDF World also operates in Lagoi Plaza, Bintan, and in
          Tachiliek, a border town found in eastern Myanmar near Thailand's
          border.
        </p>
        <p>
          EDF is currently planning to open more outlets in Jakarta downtown
          area.
        </p>
      </div>
    );
  }
}
export default About;
