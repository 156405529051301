import React, { Component } from "react";
import Carousel from "../../components/Carousel";
import Introduction from "../../components/Introduction";
import Map from "../../components/Map";
import Grid from "@material-ui/core/Grid";
import "./Countries.css";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

import changiairport from "../../assets/images/changiairport.jpg";
import mbs from "../../assets/images/mbs.jpg";
import supertree from "../../assets/images/supertree.jpg";
import shelves from "../../assets/images/shelves.jpg";

interface IUpdates {
  countryData: any;
}

class Countries extends Component {
  render() {
    const images = [
      {
        original: changiairport,
        thumbnail: changiairport
      },
      {
        original: mbs,
        thumbnail: mbs
      },
      {
        original: shelves,
        thumbnail: shelves
      }
    ];

    const images1 = [
      {
        original: changiairport,
        thumbnail: changiairport
      },
      {
        original: mbs,
        thumbnail: mbs
      },
      {
        original: supertree,
        thumbnail: supertree
      }
    ];
    return (
      <div>
        <div className="imgGallery">
          <ImageGallery
            items={images1}
            autoPlay={true}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
          />
        </div>
        <div className="IntroGrid">
          <Grid container={true} className="introboxHome">
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="introSG">
          <div className="sgWrapper">
            <h1>
              SINGAPORE
              <div className="line2" />
            </h1>
            <p>Get Everyone Shopping</p>
          </div>
        </div>
            </Grid>
          </Grid>
        </div>
      
        {/* <div className="toBK">
          <p>
            To <br />
            Bangkok <br /> →
          </p>
        </div> */}
        <div className="gridWrapper3">
          <Grid container={true} spacing={8}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Card className="news">
                <div className="newsTitle">
                  <h1>NEWS</h1>
                  <div className="line3" />
                  <p>Coming Soon
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </p>
                </div>
              </Card>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <CardMedia
                style={{ height: "100%" }} // og 59%
                image={require("../../assets/images/supertree.jpg")}
              />
            </Grid>
          </Grid>
        </div>
        <div className="galleryTitle">
          <h1>GALLERY</h1>
        </div>
        <div className="imgGallerysg">
          <ImageGallery
            items={images}
            autoPlay={false}
            showThumbnails={true}
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={true}
            thumbnailPosition={"bottom"}
          />
        </div>
      </div>
    );
  }
}
export default Countries;
