import * as React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Carousel.css";

import bali from "../../assets/images/bali.jpg";
import mbs from "../../assets/images/mbs.jpg";
import kuta from "../../assets/images/kutabeach.jpg";

class Carousel extends React.Component {
  public state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    top: false
  };

  public render() {
    const images = [
      {
        original: bali
      },
      {
        original: mbs
      },
      {
        original: kuta
      }
    ];

    return (
      <div className="imgGallery">
        <ImageGallery
          items={images}
          autoPlay={true}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={false}
        />
      </div>
    );
  }
}
export default Carousel;
