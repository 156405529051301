export default [
  {
    image: "absolut.png"
  },
  {
    image: "bacardi.png"
  },
  {
    image: "smirnoff.png"
  }
];
