import * as React from "react";
import "./Updates.css";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

interface IUpdates {
  contentData: any;
}

class Updates extends React.Component<IUpdates> {
  public render() {
    const { contentData } = this.props;
    return (
      <div className="updates">
        <CardActionArea>
          <CardMedia
            className="image1"
            style={{ height: 100, paddingTop: "56%" }}
            image={require(`../../assets/images/${contentData.image}`)}
            title={contentData.title}
          />
          <CardContent className="content">
            <h2>{contentData.title}</h2>
            <Typography component="p">{contentData.paragraph}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary">
            Learn More
          </Button>
        </CardActions>
      </div>
    );
  }
}
export default Updates;
