import React, { Component } from "react";
import "./App.css";
import Navigation from "./components/Navigation";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Countries from "./pages/Countries";
import Contact from "./pages/Contact";
import { Helmet } from "react-helmet";
import "core-js";

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>EDF WORLD</title>
            <link rel="canonical" href="http://mysite.com/example" />
          </Helmet>
          <Navigation />
          <div className="app">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/Home" component={Home} />
              <Route path="/Countries" component={Countries} />
              <Route path="/Contact" component={Contact} />
            </Switch>
          </div>
          <Footer />
        </>
      </Router>
    );
  }
}

export default App;
