export default [
  {
    image: "kutabeach.jpg",
    title: "Best Price",
    paragraph:
      "Shop with confidence – browse through the categories of beauty, confectionary, wine & spirits and many more, where you can pick out a gift or anything that catches your fancy, we offer the best price."
  },
  {
    image: "shelves.jpg",
    title: "Widest Selection",
    paragraph:
      "We travel the world to bring to you our widest selection. Don’t forget to visit us, be the first to check out our travel retail exclusives on special and limited edition Wine and Spirits at promotional prices."
  },
  {
    image: "singapore.jpg",
    title: "Discover Now",
    paragraph:
      "Go with us and to discover the latest events and be informed on the latest updates within and around us"
  }
];
