import * as React from "react";
import "./Map_marker.css";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

class Map_marker extends React.Component {
  public render() {
    return (
      <Link to={"/Countries"}>
        <div className="marker">
          <div className="visit">
            <p>
              Visit <br /> →
            </p>
          </div>
          <div className="name">
            <p>Singapore</p>
          </div>
        </div>
      </Link>
    );
  }
}
export default Map_marker;
