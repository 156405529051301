import * as React from "react";
import "./Partners.css";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";

interface IUpdates {
  partnerData: any;
}

class Partners extends React.Component<IUpdates> {
  public render() {
    const { partnerData } = this.props;
    return (
      <Grid container={true} spacing={8}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="updates">
            {/* <CardActionArea>
              <CardMedia
                className="image2"
                style={{ paddingTop: "35%", backgroundSize: "contain" }}
                image={require(`../../assets/images/${partnerData.image}`)}
              />
            </CardActionArea> */}
          </div>
        </Grid>
      </Grid>
    );
  }
}
export default Partners;
